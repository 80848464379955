body {
    background-color: #ede9e6;
    /* font-family: 'Playfair Display', serif; */
}

.shopContainer {
    padding-top: 30px; 
}

.product.image {
    width: 50%!important;
    min-width: 50%!important;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .product.image {
        width: 100%!important;
        min-width: 100%!important;
        text-align: center;
    }

    .horizontal.segments {
        display: block!important;
    }

    .column {
        width: 100%!important;
        margin-left: auto!important;
        margin-right: auto!important;
    }
  }

.product.image img {
    margin-left: auto;
    margin-right: auto;
}

.product-description {
    font-style: italic;
}

.field.fullWidthField {
    max-width: 80%!important;
}

h3.right.floated {
    float: right;
}

.splide__track {
    max-height: 70vh;
}

.header.image-header {
    font-style: italic;
    color: #606060!important;
    font-family: 'Playfair Display', serif;
}

.header.image-header:hover {
    color: #000000!important;
}

.main-container {
    min-height: 100vh;
    position: relative;
    padding-bottom: 4.5rem;
}

.product.details.horizontal.segments {
    margin-top: 0!important;
}

.splide__slide video, .splide__slide img {
    width: 100%!important;
}

.white-box {
    padding: 20px;
    box-shadow: 2.8px 2.8px 2.2px rgb(0 0 0 / 3%), -2.8px -2.8px 2.2px rgb(0 0 0 / 3%), 6.7px 6.7px 5.3px rgb(0 0 0 / 5%), -6.7px -6.7px 5.3px rgb(0 0 0 / 5%), 0 12.5px 10px rgb(0 0 0 / 6%), 0 25.3px 13.9px rgb(0 0 0 / 7%), 0 41.8px 33.4px rgb(0 0 0 / 9%), 0 -47px 80px rgb(0 0 0 / 12%);
;
    background-color: white;
    margin-bottom: 3em;
}

.footer {
    width: 100%;
    text-align: center;
    background: black;
    font-style: italic;
    font-weight: 100;
    color: white;
    min-height: 2.5rem;
    position: absolute;
    bottom: 0;
    padding: 1rem;
}

.ui.steps .step>i.icon {
    font-size: 1.5em !important; 
}

.ui.steps .step:not(.active), .ui.steps .step:not(.active)::after {
    background: #d8d5d5 !important;
    color: rgb(134 132 132 / 87%) !important;
    border: none;
}

.ui.steps .step.active .title {
    color: white!important;
}


.tracking-icon  {
    box-shadow: 0 0 0 0.1em #575656 inset;
    -webkit-animation: pulse 2s infinite!important;
}
  
@-webkit-keyframes pulse {
    0% {
        color: #918e8e;
    }
    50% {
        color: #000000
    }
    100% {
        color: #918e8e;
    }
}
  
@keyframes pulse {
    0% {
        color: #918e8e;
    }
    50% {
        color: #000000
    }
    100% {
        color: #918e8e;
    }
}

.ui.selection.dropdown .menu {
    max-height: 50vh!important;
    height: 50vh;
}

.stock-label {
    background-color: #c56c6c!important;
    color: white!important;
}

.custom-sizes-container {
    background: rgb(224 225 226);
    padding: 20px;
    border-radius: 3px;
}

.sidebar.menu {
    padding-top: 20px;
    background: black!important;
    color: white!important;
}

.sidebar.menu .a, .sidebar.menu .item .header, .sidebar.menu .item .menu .item{
    color: white!important;
}

.order-row td {
    border-top: 2px solid teal!important;
}

.w100 {
    width: 100%!important;
}

.orders-table {
    margin-top: 40px!important;
    border: 2px solid teal!important;
}

.order-row {
    background-color: #bddcdb!important;
}

.menu a:visited, .menu a:active, .menu a:link {
    border: none;
    color: black;
}

.menu {
    height: 5rem;
}

.grid-container {
    display: grid;
    grid-template-columns: 50% 50%;

}
  .grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }


  .welcome-container {
      position: relative;
  }

.welcome-div{
    position: absolute;
    bottom: 100px;
    left: 100px;
    background: white;
    width: 50%;
    padding:20px;
    box-shadow: 2.8px 2.8px 2.2px rgb(0 0 0 / 3%), -2.8px -2.8px 2.2px rgb(0 0 0 / 3%), 6.7px 6.7px 5.3px rgb(0 0 0 / 5%), -6.7px -6.7px 5.3px rgb(0 0 0 / 5%), 0 12.5px 10px rgb(0 0 0 / 6%), 0 25.3px 13.9px rgb(0 0 0 / 7%), 0 41.8px 33.4px rgb(0 0 0 / 9%), 0 -47px 80px rgb(0 0 0 / 12%)
}

.tema {
    font-family: 'Playfair Display', serif;
    font-size: xx-large;
}

.bytamara {
    font-family: 'Allison', cursive;
        font-size: 2rem;
}

.logo-container {
    flex-direction: column;
    padding:3px!important;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif!important;
}

h2 {
    font-weight: 100!important;
}

.landing-page-title {
    margin-top: 3rem!important;
    text-align: center;
}

.landing-page-subtitle {
    margin-bottom: 3rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    font-weight: 100;
}

.landing-product-title {
    margin-top: 0;
    padding: 5px;
}

.landing-black-button {
    width: 50%;
    margin-left: 25%!important;
}

.next-row {
    margin-top: 4rem!important;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .bytamara, .welcome-container {
        display: none;
    }

    .grid-container {
        grid-template-columns: 100%
    }

    .landing-page-subtitle {
        width: 100%;
    }
  }


  .close-label {
    width: 3rem;
    right: 0;
    top: 0;
    left: auto!important;
    color: white!important;
    background: black!important;
  }

  .sold-out {
    position: absolute;
    top: 20%;
    width: 80%;
    left: 10%;
  }

  .ui.menu .item {
      padding: .7em 1.1em!important;
  }
.ba {
    border-style: solid;
    border-width: 1px;
}

.bt {
    border-top-style: solid;
    border-top-width: 1px;
}

.bb {
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.b--black {
    border-color: #000;
}

.br-100 {
    border-radius: 100%;
}

.dib {
    display: inline-block;
}

.avenir {
    font-family: 'avenir next', avenir, sans-serif;
}

.baskerville {
    font-family: baskerville, serif;
}

.i {
    font-style: italic;
}

.fw1 {
    font-weight: 100;
}

.fw4 {
    font-weight: 400;
}

.h2 {
    height: 2rem;
}

.tracked {
    letter-spacing: .1em;
}

.link {
    text-decoration: none;
    transition: color .15s ease-in;
}

.link:link, .link:visited {
    transition: color .15s ease-in;
}

.link:hover {
    transition: color .15s ease-in;
}

.link:active {
    transition: color .15s ease-in;
}

.link:focus {
    transition: color .15s ease-in;
    outline: 1px dotted currentColor;
}

.mw7 {
    max-width: 80rem;
}

.w2 {
    width: 2rem;
}

.black-80 {
    color: rgba(0, 0, 0, .8);
}

.white {
    color: #fff;
}

.bg-black-80 {
    background-color: rgba(0, 0, 0, .8);
}

.bg-white {
    background-color: #fff;
}

.pa3 {
    padding: 1rem;
}

.pv4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.mb0 {
    margin-bottom: 0;
}

.mt2 {
    margin-top: .5rem;
}

.mt4 {
    margin-top: 2rem;
}

.tc {
    text-align: center;
}

.ttu {
    text-transform: uppercase;
}

.f1 {
    font-size: 3rem;
}

.f6 {
    font-size: .875rem;
}

.center {
    margin-right: auto;
    margin-left: auto;
}

.bg-animate, .bg-animate:hover, .bg-animate:focus {
    transition: background-color .15s ease-in-out;
}

@media screen and (min-width: 60em) {
    .ph4-l {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .f5-l {
        font-size: 1rem;
    }
}

/* Landing */
.landing {
    background-color: white;
}

.bt {
    border-top-style: solid;
    border-top-width: 1px;
}

.b--black-10 {
    border-color: rgba(0, 0, 0, .1);
}

.cf:before, .cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

.dn {
    display: none;
}

.db {
    display: block;
}

.dt {
    display: table;
}

.dtc {
    display: table-cell;
}

.fl {
    float: left;
    _display: inline;
}

.serif {
    font-family: georgia, times, serif;
}

.helvetica {
    font-family: 'helvetica neue', helvetica, sans-serif;
}

.fw6 {
    font-weight: 600;
}

.fw9 {
    font-weight: 900;
}

.vh-100 {
    height: 100vh;
}

.lh-title {
    line-height: 1.25;
}

.lh-copy {
    line-height: 1.5;
}

.mw6 {
    max-width: 32rem;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.black {
    color: #000;
}

.bg-light-pink {
    background-color: #ffa3d7;
}

.pl2 {
    padding-left: .5rem;
}

.pr1 {
    padding-right: .25rem;
}

.pb3 {
    padding-bottom: 1rem;
}

.pb5 {
    padding-bottom: 4rem;
}

.pv4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.ph3 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.f2 {
    font-size: 2.25rem;
}

.f4 {
    font-size: 1.25rem;
}

.f5 {
    font-size: 1rem;
}

.f6 {
    font-size: .875rem;
}

.measure {
    max-width: 30em;
}

.center {
    margin-right: auto;
    margin-left: auto;
}

.v-mid {
    vertical-align: middle;
}

@media screen and (min-width: 30em) {
    .fw4-ns {
        font-weight: 400;
    }
}

@media screen and (min-width: 30em) and (max-width: 60em) {
    .ph4-m {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .f3-m {
        font-size: 1.5rem;
    }

    .f5-m {
        font-size: 1rem;
    }
}

@media screen and (min-width: 60em) {
    .dt-l {
        display: table;
    }

    .dtc-l {
        display: table-cell;
    }

    .w-50-l {
        width: 50%;
    }

    .pl0-l {
        padding-left: 0;
    }

    .pl2-l {
        padding-left: .5rem;
    }

    .pr0-l {
        padding-right: 0;
    }

    .pr2-l {
        padding-right: .5rem;
    }

    .pr3-l {
        padding-right: 1rem;
    }

    .pv4-l {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .ph5-l {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .f-subheadline-l {
        font-size: 5rem;
    }

    .f1-l {
        font-size: 3rem;
    }

    .f4-l {
        font-size: 1.25rem;
    }

    .measure-l {
        max-width: 30em;
    }
}


